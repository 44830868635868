<script>/**
 * Base Profile Form component
 */
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import imageCropUpload from 'vue-image-crop-upload';
export default {
    components: {vueDropzone: vue2Dropzone, imageCropUpload},
    data() {
        return {
            user: this.$store ? this.$store.state.auth.currentUser : {} || {},
            firstName: '',
            lastName: '',
            biography: '',
            socialMedia: {
                instagram: '',
                facebook: '',
                website: '',
                linkedIn: '',
                twitter: ''
            },
            show: false,
            avatarOptions: {
              url:`${process.env.VUE_APP_API}/general/avatar-upload`,
              headers:{
                'x-access-token':  JSON.parse(window.localStorage.getItem('auth.token'))
              },
              locale:{
                hint: 'Πατήστε εδώ ή σύρετε μία εικόνα',
                loading: 'Ανεβαίνει…',
                noSupported: 'Browser is not supported, please use IE10+ or other browsers',
                success: 'Επιτύχια!',
                fail: 'Αποτυχία',
                preview: 'Προεπισκόπηση',
                btn: {
                  off: 'Ακύρωση',
                  close: 'Κλείσιμο',
                  back: 'Πίσω',
                  save: 'Αποθήκευση'
                },
                error: {
                  onlyImg: 'Παρακαλώ εισάγετε εικόνα',
                  outOfSize: 'Η εικόνα ξεπερνάει το όριο: ',
                  lowestPx: 'Το μέγεθος της εικόνας παραείναι μικρό. Αναμενόμενο ελάχιστο μέγεθος: '
                }
              }
            },
            imgDataUrl: '', // the datebase64 url of created image
            updateError: null,
            isBtnDisabled: true,
            previousFormState: '',
            currentFormState: '',
            error: '',
            dropzoneOptions: {
                url: `${process.env.VUE_APP_API}/general/image-upload`,
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                headers: { "x-access-token": JSON.parse(window.localStorage.getItem('auth.token')) },
                maxFiles: 1,
                resizeHeight: 300,
                dictDefaultMessage: 'Σύρτε εδώ μια εικόνα ή πατήστε πάνω στο πλαίσιο',
            },
            tempLogo: '',
            replaceLogo: false
        }
    },
    mounted: function(){
        this.firstName = this.user.firstName;
        this.lastName = this.user.lastName;
        this.biography = this.user.biography;
        this.socialMedia = {
            instagram: this.user.userSocialMedia.instagram,
            facebook: this.user.userSocialMedia.facebook,
            website: this.user.userSocialMedia.website,
            linkedIn: this.user.userSocialMedia.linkedIn,
            twitter: this.user.userSocialMedia.twitter
        };
        this.previousFormState = this.firstName + this.lastName + this.biography + JSON.stringify(this.socialMedia);
        this.currentFormState = this.previousFormState;

        if(this.$route.query.activation_hash){
            this.activateEmail(this.$route.query.activation_hash);
        }

        this.$refs['uploadLogo'].$on('vdropzone-complete', res =>{
            this.tempLogo = res.xhr.response;
        })
    },

    created: function(){
        this.$store.dispatch('auth/refetchUser');
        this.$store.subscribe((mutation, state) => {
            if(mutation.type === 'auth/SET_CURRENT_USER'){
                this.user = this.$store.state.auth.currentUser;
            }
        });
    },

    beforeDestroy() {
        if(!this.isBtnDisabled){
            this.updateBaseProfile();
        }
    },

    methods:{
        toggleShow() {
          this.show = !this.show;
        },
        cropUploadSuccess(jsonData, field){
          this.$store.dispatch('auth/updateUserImage', jsonData.profileImage);
          this.show = false;
        },
        cropUploadFail(status, field){
          this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η αποθήκευση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
        },
        activateEmail(hash){
            this.$axios.post(`/users/verify-email`, {activationHash: hash}).then(result=>{
                if(result.data.type === 'FIRST_TIME'){
                    this.$store.dispatch('auth/verifyEmail');
                    this.$notify({group: 'athlisis-notifications', type:'success', duration: 10000, title: this.$t('success.title'), text: 'Το e-mail σας ενεργοποιήθηκε επιτυχώς!'});
                }

            }).catch(e=>{
                this.error = 'Κάτι πήγε λάθος με την ενεργοποίηση του e-mail!'
            });
        },
        updateBaseProfile: function(){
            this.isBtnDisabled =true;
            this.previousFormState = this.currentFormState;


            let newUserDetails = {
                firstName: this.firstName,
                lastName: this.lastName,
                biography: this.biography,
                userSocialMedia: {
                    instagram: this.socialMedia.instagram,
                    facebook: this.socialMedia.facebook,
                    website: this.socialMedia.website,
                    linkedIn: this.socialMedia.linkedIn,
                    twitter: this.socialMedia.twitter
                }
            };

            return this.$store.dispatch('auth/updateUser',newUserDetails).then(result =>{

                this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Τα στοιχεία ενημερώθηκαν επιτυχώς!'});
                this.checkForDifferences()

            }).catch( error => {
                this.updateError = error;
                this.checkForDifferences()
                this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η αποθήκευση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
            })
        },
        checkForDifferences: function(){
            this.currentFormState = this.firstName + this.lastName + this.biography + JSON.stringify(this.socialMedia);
            this.isBtnDisabled = this.currentFormState === this.previousFormState;
        },

        reSendEmailVerification(){
            this.$axios.put(`/users/resend-verification`).then(result=>{
                console.log(result);
            })
        },

        cancelUpload(){
            this.tempLogo = '';
            this.replaceLogo = false;
            this.$refs['uploadLogo'].removeAllFiles();
        },

        saveLogo(){
            this.$store.dispatch('auth/updateUserLogo', this.tempLogo);
            this.cancelUpload();
        }


    }
}
</script>

<style lang="scss">
.vue-image-crop-upload .vicp-wrap .vicp-operate a{
  color:$color_primary;
}
</style>

<template>
    <div>
        <b-alert :show="!user.isActivated && !isEducational" variant="warning">Δεν έχετε επιβεβαιώσει το e-mail σας.
            <b-button variant="link" class="p-0" @click="reSendEmailVerification"><strong>Επαναποστολή συνδέσμου επιβεβαίωσης</strong></b-button>
        </b-alert>
        <b-alert :show="error !== ''" variant="danger">Κάτι πήγε λάθος με την ενεργοποίηση του e-mail!</b-alert>



        <div class="row mb-3">
          <b-card v-if="!isEducational" class="col-12 mb-3">
            <b-card-title>Εικόνα προφίλ</b-card-title>

            <image-crop-upload field="file"
                               @crop-upload-success="cropUploadSuccess"
                               @crop-upload-fail="cropUploadFail"
                               lang-type="en"
                               :model-value.sync="show"
                               :lang-ext="avatarOptions.locale"
                               :width="300" :height="300" :url="avatarOptions.url" :headers="avatarOptions.headers" img-format="png"></image-crop-upload>

            <img v-if="user && user.image" :src="user.image" class="rounded-image" style="width:150px;height:150px;border-radius:99%;">
            <div>
              <b-btn variant="primary" class="btn my-3" @click="toggleShow">Προσθήκη / αλλαγή εικόνας</b-btn>
            </div>

          </b-card>

          <b-card v-if="!isEducational" class="col-12 mb-3">
            <b-card-title>Λογότυπο</b-card-title>

            <b-card-sub-title>Ανεβάστε το λογότυπό σας (για εκτυπώσεις κ.λπ.)</b-card-sub-title>
            <div class="mt-4">



              <img v-if="user.logo || tempLogo" :src="tempLogo ? tempLogo : user.logo" height="150" />
              <b-button v-if="user.logo" variant="primary" class="m-3" @click="replaceLogo = !replaceLogo"><i class="fa fa-edit"></i></b-button>
              <br>
              <vue-dropzone v-show="(!user.logo  && !tempLogo) || replaceLogo" id="dropzone" ref="uploadLogo" :options="dropzoneOptions" class="mt-3 mb-3"></vue-dropzone>
              <b-button v-if="tempLogo" variant="success" class="m-2" @click="saveLogo">Αποθήκευση λογοτύπου</b-button> <b-button v-if="tempLogo" variant="danger" class="m-1" @click="cancelUpload">Ακύρωση</b-button>

            </div>

          </b-card>
          <b-card class="col-12 mb-3">
            <b-card-title>Πληροφορίες Χρήστη</b-card-title>
            <b-card-sub-title>Επεξεργαστείτε τις βασικές σας πληροφορίες & τα κοινωνικά σας δίκτυα</b-card-sub-title>
            <div class="mt-4">
              <b-form @submit.prevent="updateBaseProfile">
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group id="input-group-1" label="Όνομα" label-for="input-1" >
                      <b-form-input id="input-1" v-model="firstName" type="text" placeholder="Εισάγετε το όνομά σας" required @input="checkForDifferences"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group id="input-group-2" label="Επώνυμο" label-for="input-2">
                      <b-form-input id="input-2" v-model="lastName" type="text" placeholder="Εισάγετε το επώνυμό σας" required @input="checkForDifferences"></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-12">
                    <b-form-group id="input-group-3" label="Βιογραφικό (προαιρετικό)" label-for="input-3">
                      <b-form-textarea id="input-3" v-model="biography" type="textarea" placeholder="Εισάγετε το βιογραφικό σας" rows="6" @input="checkForDifferences"></b-form-textarea>
                    </b-form-group>
                  </div>
                </div>



              <div v-if="!isEducational">
                <h5 class="mb-3 text-primary p-2">
                  <i class="fe-share-2 mr-2" ></i> Κοινωνικά δίκτυα
                </h5>
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group id="input-group-4" label="Ιστοσελίδα (προαιρετικό)" label-for="input-4" >
                      <b-form-input id="input-4" v-model="socialMedia.website" type="text" placeholder="Εισάγετε την ιστοσελίδα σας (wwww.example.com)" @input="checkForDifferences"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group id="input-group-5" label="Facebook (προαιρετικό)" label-for="input-5" >
                      <b-form-input id="input-5" v-model="socialMedia.facebook" type="text" placeholder="Εισάγετε το facebook id σας (www.facebook.com/example)" @input="checkForDifferences"></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <b-form-group id="input-group-6" label="Instagram (προαιρετικό)" label-for="input-6" >
                      <b-form-input id="input-6" v-model="socialMedia.instagram" placeholder="Εισάγετε το Instagram username σας (@athlisis.com)" type="text" @input="checkForDifferences"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group id="input-group-7" label="Twitter (προαιρετικό)" label-for="input-7" >
                      <b-form-input id="input-7" v-model="socialMedia.twitter" type="text" placeholder="Εισάγετε το twitter id σας (@athlisis)" @input="checkForDifferences"></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <b-form-group id="input-group-8" label="LinkedIn (προαιρετικό)" label-for="input-8" >
                      <b-form-input id="input-8" v-model="socialMedia.linkedIn" placeholder="Εισάγετε το linkedIn σας (www.linkedin.com/athlisis)" type="text" @input="checkForDifferences"></b-form-input>
                    </b-form-group>
                  </div>
                </div>

              </div>

                <div class="mt-2 text-right">
                  <save-button type="submit" class="mt-2 ml-auto" :disabled="isBtnDisabled"></save-button>
                </div>

              </b-form>

              <b-alert v-model="updateError" variant="danger" dismissible>
                {{updateError}}
              </b-alert>
            </div>
          </b-card>
        </div>


    </div>
</template>
