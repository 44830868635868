<script>
import baseProfileForm from "./baseProfileForm";
import ProfileSidebar from "./ProfileSidebar";
import {mapState} from "vuex";

/**
 * Profile component
 */
export default {
  page: {
    title: 'Λογαριασμός'
  },
  components: {ProfileSidebar, baseProfileForm },
  computed:{
    ...mapState({
      user: state => state.auth.currentUser
    })
  },
  data() {
    return {
    }
  },
}
</script>
<template>
  <div id="profile-layout">
    <div class="row">
      <ProfileSidebar></ProfileSidebar>

        <div class="col-lg-9 col-xl-9">
              <baseProfileForm></baseProfileForm>
        </div>
      <!-- end col -->
    </div>
    <!-- end row-->
  </div>
</template>
